.roofing-category {
    margin-bottom: 96px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 120px;
    }

    h1 {
        font-size: 37px;
        line-height: 42px;
        margin-bottom: 16px;

        @include media-breakpoint-up(lg) {
            font-size: 64px;
            line-height: 77px;
        }
    }

    .main-container {
        margin-bottom: 48px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 64px;
        }
    }

    .brands-section {
        .row {
            row-gap: 32px;
        }
        .single-brand {
            overflow: hidden;
            .inner{
                border: 2px solid $orange;
                height: 100%;

                .image {
                    padding: 24px;
                    img {
                        max-width: 120px;
                        max-height: 120px;

                        @include media-breakpoint-up(lg) {
                            max-width: 232px;
                            max-height: 232px;
                        }
                    }
                }

                .content {
                    padding: 24px;
                    max-height: 300px;
                    height: 100%;
                    h2 {
                        font-size: 24px;
                        line-height: 38px;
                        margin-bottom: 12px;
                        color: $black;

                        @include media-breakpoint-up(lg) {
                            font-size: 28px;
                            line-height: 32px;
                        }
                    }
                    .content-inner {
                        color: $black;
                    }
                }
            }

            &:hover {
                .content {
                    background-color: $orange;
                    h2,
                    .content-inner {
                        color: $white;
                    }
                    h2 {
                        text-decoration: underline;
                    }
                }
            }
        }

        &.second-level {
            .single-brand {
                .image {
                    display: none!important;
                }
            }
        }
    }

    .roof-systems {
        .roofing-group {
            width: 100%;
            margin-bottom: 120px;
        }
        .level1 {
            color: $black;
            cursor: default;

            h2 {
                font-size: 24px;
                line-height: 28px;
                margin-bottom: 32px;

                @include media-breakpoint-up(lg) {
                    font-size: 38px;
                    line-height: 44px;
                    margin-bottom: 48px;
                }
            }
        }

        .subcategories {
            padding-left: 16px;
            padding-right: 16px;
            
            @include media-breakpoint-up(md) {
                max-width: 100%;
            }

            &.roofing-images {
                .img-wrapper {
                    img {
                        object-fit: cover;
                        width: 100%;
                        height: auto;
                        @include media-breakpoint-up(md) {
                            max-height: 28px;
                            width: auto;
                        }
                    }
                }

                .roofing-level2 {
                    margin-bottom: 24px;
                }
            }

            .single-color {
                .inner {
                    border: none!important;
                    .img-wrapper {
                        padding: 0!important;
                        position: relative;

                        img {
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }

                        .first {
                            z-index: 5;
                            position: relative;
                        }

                        .second {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            z-index: 3;
                            transition: all 0.3s ease;
                            opacity: 0;
                            visibility: hidden;
                        }
                    }
                    
                    .title-wrapper {
                        padding: 8px!important;

                        h3 {
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }

                    &:hover {
                        .img-wrapper {
                            .second {
                                z-index: 10;
                                opacity: 1;
                                visibility: visible;
                                transition: all 0.3s ease;
                            }
                        }
                    }
                }
            }

            .level2 {
                .inner {
                    border: 2px solid $orange;
                    //padding: 24px;

                    .img-wrapper {
                       height: 140px;
                       padding: 24px;

                       @include media-breakpoint-up(md) {
                        padding: 0;
                        padding-top: 24px;
                        padding-left: 24px;
                       }
                    }

                    .title-wrapper {
                        padding: 24px;
                        text-align: center;
                    }

                    h3 {
                        font-size: 14px;
                        line-height: 23px;
                        //margin-bottom: 12px;
                        color: $black;

                        @include media-breakpoint-up(md) {
                            font-size: 20px;
                        }
    
                        @include media-breakpoint-up(lg) {
                            font-size: 25px;
                            line-height: 32px;
                        }
                    }

                    .content-inner {
                        color: $black;
                    }
                }

                &:hover {
                    .inner {
                        background-color: $orange;

                        h3 {
                            text-decoration: underline;
                            color: $white;
                        }

                        .content-inner {
                            color: $white;
                        }
                    }
                }
            }
        }

        .side-menu {
            overflow-x: auto;
            position: sticky;
            top: 175px;
            background: $white;
            z-index: 1000;

            /* width */
            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
            }
            
            /* Track */
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }
            
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: $orange;
            }
            
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: $orange;
            }

            @include media-breakpoint-up(md) {
                overflow-x: unset;
            }
            .content-inner {
                position: sticky;
                top: 260px;

                ul {
                    margin: 0;
                    padding: 0;
                    display: flex;

                    @include media-breakpoint-up(md) {
                        display: block;
                    }

                    li {
                        padding-top: 12px;
                        padding-bottom: 12px;
                        list-style-type: none;
                        margin-left: 12px;
                        margin-right: 12px;

                        a {
                            font-size: 21px;
                            line-height: 30px;
                            white-space: nowrap;

                            @include media-breakpoint-up(md) {
                                white-space: initial;
                            }
                        }
                    }
                }
            }
        }
    }

    .documents-section {
        h2 {
            font-size: 30px;
            line-height: 34px;
            margin-bottom: 32px;

            @include media-breakpoint-up(lg) {
                font-size: 50px;
                line-height: 57px;
            }
        }

        .row {
            flex-direction: column;
        }

        .single-document {
            margin-bottom: 24px;

            &:last-of-type {
                margin-bottom: 0;
            }

            a {
                font-family: $font-bold;
                display: inline-block;

                @include blackHover;

                .inner {
                    display: flex;
                    align-items: center;
                }

                span {
                    margin-right: 8px;
                }
            }
        }
    }
}
