#news-container {
    position: relative;
    z-index: 100;

    .list-loader {
        @include loader;
    }

    .news-top {
      margin-bottom: 20px;  
    }

    .news-filters {
        ul {
            padding-left: 25px;
            padding-right: 25px;
            padding-bottom: 10px;
            display: flex;
            justify-content: space-between;
            overflow-x: auto;

            
            &::-webkit-scrollbar-track {
                background-color: transparent;
            }
    
            &::-webkit-scrollbar {
                height: 4px;
            }
    
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: rgba(0, 0, 0, 0.2);
            }

            li {
                list-style-type: none;
                width: 100%;
                text-align: center;
                a {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    display: block;
                    color: #0d0d0d;
                    font-size: 21px;
                    line-height: 29px;
                    background: 0 0;
                    cursor: pointer;
                    text-decoration: none;
                    padding: 14px 38px;
                    white-space: nowrap;
                    transition: all 0.5s ease;
                    border-bottom: 2px solid rgba($orange, 0.2);
                    font-family: $font-primary;
                    font-weight: 400;
                }

                &.active {
                    a {
                        color: $red;
                        font-family: $font-bold;
                        font-weight: 700;
                        border-bottom: 2px solid rgba($red, 1);
                    }
                }

                &:hover {
                    a {
                        background-color: $lightgray3;
                    }
                    
                }
            }
        }
    }

    .news-list-dynamic {
        position: relative;

        .news-wrapper {
            margin-bottom: 96px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 144px;
            }
        }
    }

    .list-loader {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($orange, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20;
    }

    .news-list-pagination {
        padding-bottom: 96px;
        @include media-breakpoint-up(lg) {
            padding-bottom: 144px;
        }

        .pagination {
            display: flex;
            justify-content: center;
            * {
                font-size: 21px;
                line-height: 29px;
            }
            .pagination-page {
                width: 64px;
                height: 64px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: $font-primary;
                font-weight: 400px;
                color: $black;
                border-bottom: 4px solid transparent;

                &:hover {
                    background-color: $lightgray4;
                }

                &.pagination-active {
                    font-family: $font-bold;
                    font-weight: 700;
                    color: $orange;
                    border-bottom: 4px solid $orange;

                    &:hover {
                        background-color: rgba($lightgray4, 0);
                    }
                }
            }
        }
    }
}

.article-single {
    //padding-top: 64px;
    @include media-breakpoint-up(lg) {
        padding-top: 0; 
    }
    @include media-breakpoint-up(xl) {
        padding-top: 64; 
    }

    .article-single-banner {
        padding-top: 28px;
        padding-bottom: 32px; 


        img {
            object-fit: cover;
            width: 100%;
            height: auto;
        }

        .article-banner-content {
            

            @include media-breakpoint-up(md) {
                margin-top: 48px;
            }

            .breadcrumbs {
                padding: 0;
                margin-bottom: 24px;
                margin-top: 0;
                @include media-breakpoint-up(lg) {
                    margin-top: 64px;
                    margin-bottom: 64px; 
                }
            }

            h1 {
                font-size: clamp(37px, calc(2.3125rem + ((1vw - 3.2px) * 1.875)), 67px);
                line-height: clamp(41px, calc(2.5625rem + ((1vw - 3.2px) * 2.0625)), 74px);
                margin-bottom: 16px;
            }

            .date {
                margin-bottom: 32px;
            }
        }
    }

    .article-single-content {
        padding-bottom: 96px;
        .content-wrapper {
            padding-top: 0;
            padding-bottom: 0;

            .content {
                padding: 0;

                a {
                    color: $orange;
                    text-decoration: underline;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: auto;
                }
            }
        }
        
        .content-inner {
            a {
                color: $orange;
                @include orangeHover;
            }
        }
    }    
}

.content-gallery-slider {
    margin-top: 32px;
    margin-bottom: 32px;

    .splide__arrows {
        button {
            width: 48px;
            height: 48px;
            border: 1px solid $black;
            background-color: $white;
        }
    }
}