#footer {
    &.landing {
        .line {
            display: none;
        }
    }
}

.landing-page {

    .top {
        background: $lightgray5;
        position: relative;
        text-align: center;
        padding-top: 24px;
        padding-bottom: 24px;
    }

    .landing-visual {
        position: relative;
        .landing-text {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: $white;
        }
    }

    .main-container {
        padding-top: 64px;
        padding-bottom: 64px;

        .btn-orange {
            font-size: 20px;
            line-height: 24px;
            padding: 24px 48px;
        }

        list-style-position: inside;
    }

    .desktop-img {
        margin-bottom: 48px;

        @include media-breakpoint-up(xl) {
            position: absolute;
            left: 100px;
            bottom: -41px;
            margin-bottom: 0;
            width: 250px;
            height: 250px;
        }

        @media screen and(min-width:1989px) {
            bottom: -75px;
            width: 350px;
            height: 350px;
        }
    }

    .aside {
        display: flex;
        justify-content: center;
        margin-top: 32px;

        .inner {
            width: 300px;
            background: $white;
            padding: 24px;
            border: 1px solid $orange;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

            @include media-breakpoint-up(md) {
                width: 200px;
            }

            @include media-breakpoint-up(xl) {
                width: 300px;
            }
        }

        @include media-breakpoint-up(md) {
            position: fixed;
            top: 50%;
            right: 20px;
            z-index: 10;
            margin-top: 0;
        }

        h2 {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 8px;
            display: block;

            @include media-breakpoint-up(md) {
                font-size: 28px;
                line-height: 32px;
            }
        }

        p {
            &:first-of-type {
                margin-top: 0;
            }

            @include media-breakpoint-up(md) {
                font-size: 20px;
                line-height: 26px;
            }
        }
    }
}