.breadcrumbs {
    color: $black;
    margin-top: 48px;
    margin-bottom: 64px;

    @include media-breakpoint-up(lg) {
        margin-top: 32px;
        margin-bottom: 48px;
    }

    *{
        font-size: 14px;
        line-height: 20px;
    }

    a {
        color: $gray2;
    }

    .separator {
        margin-left: 8px;
        margin-right: 8px;
    }
}