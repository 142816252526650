#our-services {
    .top-services {
        margin-bottom: 48px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 64px;
        }
        h1 {
            font-size: 37px;
            line-height: 41px;
            margin-bottom: 12px;

            @include media-breakpoint-up(lg) {
                font-size: 64px;
                line-height: 77px;
            }
        }
    }

    .services-section {
        margin-bottom: 96px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 120px;
        }
    }
}

.service-single {
   .service-single-banner {
        margin-bottom: 48px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 64px;
        }
        h1 {
            font-size: 37px;
            line-height: 41px;
            margin-bottom: 16px;

            @include media-breakpoint-up(lg) {
                font-size: 64px;
                line-height: 77px;
            }
        }
    }

    .services-content {
        padding-top: 48px;
        padding-bottom: 48px;

        @include media-breakpoint-up(lg) {
            padding-top: 64px;
            padding-bottom: 64px;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: auto;
            display: block;
        }

        &.gray {
            background-color: $lightgray3;
        }

        .content {
            a {
                color: $orange;
                @include orangeHover;
            }
        }
    }
}

.other-services {
    background-color: $lightgray3;
    padding-top: 48px;
    padding-bottom: 48px;

    @include media-breakpoint-up(lg) {
        padding-top: 96px;
        padding-bottom: 96px;
    }

    .service-tile:hover {
        background-color: transparent;
    }
}